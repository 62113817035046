<template>
   <Toolbar class="mb-4">
      <template #end>
         <router-link to="/doctor"
            ><Button
               label="Back"
               icon="pi pi-arrow-left"
               class="p-button-success mr-2 p-button-sm"
         /></router-link>
      </template>
   </Toolbar>
   <div>
      <div class="col-10">
         <div class="card">
            <Toast />
            <ConfirmDialog></ConfirmDialog>
            <div class="p-fluid grid">
               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <FileUpload
                        mode="basic"
                        name="doctor.profile_image_media"
                        accept="image/*"
                        :maxFileSize="100000000"
                     />
                  </span>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <InputText id="name" type="text" v-model="doctor.name" />
                     <label for="name">Doctor Name</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('name')"
                     :vuelidateErrors="{
                        errors: v$.doctor.name.$errors,
                        value: 'Name',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <InputText
                        id="email"
                        type="email"
                        v-model="doctor.email"
                     />
                     <label for="email">Email</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('email')"
                     :vuelidateErrors="{
                        errors: v$.doctor.email.$errors,
                        value: 'Email',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <InputText id="phone" type="text" v-model="doctor.phone" />
                     <label for="phone">Phone</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('phone')"
                     :vuelidateErrors="{
                        errors: v$.doctor.phone.$errors,
                        value: 'Phone',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-6">
                  <span class="p-float-label">
                     <Calendar id="birth_date" v-model="doctor.birth_date" />
                     <label for="birth_date">Birthdate</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('birth_date')"
                     :vuelidateErrors="{
                        errors: v$.doctor.birth_date.$errors,
                        value: 'birth_date',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-6">
                  <span class="p-float-label">
                     <Dropdown
                        id="nrc"
                        v-model="doctor.gender"
                        :options="gender"
                        optionLabel="value"
                        optionValue="code"
                     />
                     <label for="nrc">Gender</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('gender')"
                     :vuelidateErrors="{
                        errors: v$.doctor.gender.$errors,
                        value: 'gender',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-4">
                  <span class="p-float-label">
                     <Dropdown
                        id="nrc"
                        v-model="doctor.nrc_prefix_id"
                        :options="nrcPrefixs"
                        optionLabel="prefix"
                        optionValue="id"
                     />
                     <label for="nrc">9/WaTaNa</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('nrc_prefix_id')"
                     :vuelidateErrors="{
                        errors: v$.doctor.nrc_prefix_id.$errors,
                        value: 'Nrc',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-4">
                  <span class="p-float-label">
                     <Dropdown
                        id="citizen"
                        v-model="doctor.nrc_citizen"
                        :options="citizens"
                        optionLabel="value"
                        optionValue="value"
                     />
                     <label for="citizen">(N)</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('nrc_citizen')"
                     :vuelidateErrors="{
                        errors: v$.doctor.nrc_citizen.$errors,
                        value: 'Nrc citizen',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-4">
                  <span class="p-float-label">
                     <InputNumber
                        id="inputnumber"
                        v-model="doctor.nrc_number"
                     />
                     <label for="inputnumber">NRC Number</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('nrc_number')"
                     :vuelidateErrors="{
                        errors: v$.doctor.nrc_number.$errors,
                        value: 'Nrc number',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <Dropdown
                        id="spec"
                        v-model="doctor.specialist_id"
                        :options="specialists"
                        optionLabel="specialist"
                        optionValue="id"
                     />
                     <label for="spec">Specialist</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('specialist_id')"
                     :vuelidateErrors="{
                        errors: v$.doctor.specialist_id.$errors,
                        value: 'Specialist need to select ',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <Dropdown
                        id="degree"
                        v-model="doctor.degree"
                        :options="degrees"
                        optionLabel="value"
                        optionValue="value"
                     />
                     <label for="degree">Degree</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('degree')"
                     :vuelidateErrors="{
                        errors: v$.doctor.degree.$errors,
                        value: 'degree',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <InputText
                        id="sama"
                        type="text"
                        v-model="doctor.sama_number"
                     />
                     <label for="sama">SAMA Number</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('sama_number')"
                     :vuelidateErrors="{
                        errors: v$.doctor.sama_number.$errors,
                        value: 'sama_number',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-6">
                  <span class="p-float-label">
                     <Dropdown
                        id="state"
                        v-model="doctor.parent_location_id"
                        :options="regions"
                        optionLabel="name"
                        optionValue="id"
                     />
                     <label for="state">State</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('parent_location_id')"
                     :vuelidateErrors="{
                        errors: v$.doctor.parent_location_id.$errors,
                        value: 'Region',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-6">
                  <span class="p-float-label">
                     <Dropdown
                        id="township"
                        v-model="doctor.child_location_id"
                        :options="townships"
                        optionLabel="name"
                        optionValue="id"
                     />
                     <label for="township">Township</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('child_location_id')"
                     :vuelidateErrors="{
                        errors: v$.doctor.child_location_id.$errors,
                        value: 'Township',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <Textarea id="address" v-model="doctor.address" rows="3" />
                     <label for="address">Address</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('address')"
                     :vuelidateErrors="{
                        errors: v$.doctor.address.$errors,
                        value: 'address',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-12">
                  <span class="p-float-label">
                     <Textarea id="desc" v-model="value10" rows="3" />
                     <label for="desc">Description</label>
                  </span>
               </div>

               <div class="field col-12 md:col-6">
                  <span class="p-float-label">
                     <Dropdown
                        id="type"
                        v-model="doctor.doctor_type_id"
                        :options="doctorTypes"
                        optionLabel="name"
                        optionValue="id"
                     />
                     <label for="type">Doctor Type</label>
                  </span>
                  <v-errors
                     :serverErrors="errorFor('doctor_type_id')"
                     :vuelidateErrors="{
                        errors: v$.doctor.doctor_type_id.$errors,
                        value: 'Doctor Type',
                     }"
                  ></v-errors>
               </div>

               <div class="field col-12 md:col-6">
                  <span class="p-float-label">
                     <InputText
                        id="other_degree"
                        type="text"
                        v-model="doctor.other_degree"
                     />
                     <label for="other_degree">Other Degree</label>
                  </span>
               </div>
            </div>
            <Toolbar class="mb-4">
               <template #end>
                  <Button
                     label="Submit"
                     class="p-button-success mr-2 p-button-sm"
                     @click="saveDoctor"
                  />
               </template>
            </Toolbar>
         </div>
      </div>
   </div>
</template>

<script>
import { mapActions } from "vuex";
import { HTTP } from "../../service/http-common";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../mixins/validationErrors";

export default {
   setup() {
      return { v$: useVuelidate() };
   },
   mixins: [validationErrors],

   data() {
      return {
         doctor: {},
         gender: [
            { value: "Male", code: "MALE" },
            { value: "Female", code: "FEMAIL" },
         ],
         citizens: [
            { value: "(N)" },
            { value: "(E)" },
            { value: "(P)" },
            { value: "(T)" },
            { value: "(Y)" },
            { value: "(S)" },
         ],
         degrees: [
            { value: "M.B.B.S" },
            { value: "Dip.Med.Sc" },
            { value: "M.Med.Sc" },
            { value: "Dr.Med.Sc" },
            { value: "B.D.S" },
            { value: "Other" },
         ],
         specialists: null,
         nrcPrefixs: null,
         doctorTypes: null,
         regions: null,
         townships: null,
         loading: false,
      };
   },

   validations() {
      return {
         doctor: {
            name: { required },
            email: { required },
            phone: { required },
            gender: { required },
            birth_date: { required },
            nrc_prefix_id: { required },
            nrc_citizen: { required },
            nrc_number: { required },
            degree: { required },
            sama_number: { required },
            parent_location_id: { required },
            child_location_id: { required },
            address: { required },
            specialist_id: { required },
            doctor_type_id: { required },
         },
      };
   },

   methods: {
      ...mapActions([
         "specialist/fetchAllSpecalists",
         "doctortype/fetchAllTypes",
         "location/fetchAllLocations",
         "location/fetchParentLocations",
         "doctor/store",
      ]),

      getSpecialists() {
         this["specialist/fetchAllSpecalists"]()
            .then((response) => {
               this.specialists = response;
            })
            .catch((error) => {
               console.log(error);
            });
      },

      getNrc() {
         return new Promise((resolve, reject) => {
            HTTP.get("v1/nrc-prefixs")
               .then((response) => {
                  resolve(response.data.data);
                  this.nrcPrefixs = response.data.data;
               })
               .catch((error) => {
                  reject(error);
               });
         });
      },

      getDoctorTypes() {
         this["doctortype/fetchAllTypes"]()
            .then((response) => {
               this.doctorTypes = response;
            })
            .catch((error) => {
               console.log(error);
            });
      },

      getRegions() {
         this["location/fetchParentLocations"]()
            .then((response) => {
               this.regions = response;
            })
            .catch((error) => {
               console.log(error);
            });
      },

      getTownships() {
         this["location/fetchAllLocations"]()
            .then((response) => {
               this.townships = response;
            })
            .catch((error) => {
               console.log(error);
            });
      },

      async saveDoctor() {
         const isFormCorrect = await this.v$.$validate();
         if (!isFormCorrect) return;

         const inputData = this.doctor;
         this["doctor/store"](inputData)
            .then(() => {
               this.loading = true;

               this.$toast.add({
                  severity: "success",
                  summary: "Confirmed",
                  detail: "Successfully created.",
                  life: 3000,
               });

               this.$router.push({ name: "doctor" });
            })
            .catch((error) => {
               console.log(error);
            });
      },
   },
   created() {
      this.getSpecialists();
      this.getNrc();
      this.getDoctorTypes();
      this.getRegions();
      this.getTownships();
   },
};
</script>

